.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    font-size: 1.2rem;
    font-family: 'Helvetica Neue', sans-serif;
    
    p {
        margin: 0;
    }
}