.logo-container {
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .solid-logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
        opacity: 0;
        z-index: 1;
    }
}

.svg-container {
    stroke: #ffd700;
    stroke-width: 10;
}

@media screen and (max-width: 768px) {
    .logo-container {
        margin-top: 20px;
        height: 50vh;
    }
}