.nav-bar {
    background: #181818;
    width: 60px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
        display: block;
        padding: 8px 0;

        img {
            display: block;
            margin: 8px auto;
            width: 24px;
            height: auto;

            &.sub-logo {
                width: 50px;
            }
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a {
            font-size: 22px;
            color: #4d4d4e;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            &:hover {
                color: #ffd700;
                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 9px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                &:after {
                    content: 'HOME';
                }
            }

            &.about-link {
                &:after {
                    content: 'ABOUT';
                }
            }

            &.contact-link {
                &:after {
                    content: 'CONTACT';
                }
            }

            &.resume-link {
                &:after {
                    content: 'RESUME';
                }
            }

            &.project-link {
                &:after {
                    content: 'PROJECTS';
                }
            }

            &.active {
                svg {
                    color: #ffd700;
                }
            }
        }
    }

    ul {
        position: absolute;
        bottom: 30px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 20px;
                line-height: 16px;
                color: #4d4d4e;

                &:hover {
                    color: #ffd700;
                }
            }
        }
    }

    &.mobile {
        display: none;
    }
}

.hamburger-icon {
    position: fixed;
    top: 15px;
    left: 15px;
    font-size: 24px;
    color: #4d4d4e;
    cursor: pointer;
    z-index: 10;
    transition: all 0.3s ease-out;

    &.open {
        color: #ffd700;
    }
}

.temporary-sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #181818;
    z-index: 5;
    transition: all 0.3s ease-out;
    
    &.open {
        left: 0;
    }

    nav {
        padding: 60px 20px 20px;

        a {
            display: flex;
            align-items: center;
            gap: 15px;
            font-size: 18px;
            color: #4d4d4e;
            text-decoration: none;
            padding: 10px 0;
            transition: color 0.3s ease-out;

            svg {
                font-size: 24px;
            }

            span {
                font-family: sans-serif;
            }

            &:hover {
                color: #ffd700;
            }

            &.active {
                color: #ffd700;
            }
        }
    }
}

// Responsive styles
@media (max-width: 770px) {
    .nav-bar {
        &.mobile {
            display: none;
        }
    }

    .hamburger-icon {
        display: block;
    }

    .temporary-sidebar {
        &.open {
            box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
        }
    }
}